//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Publicity {
 
  list(data){
    return http.get( api.publicity.get.endpoint, data );
  }

  create(data){
    return http.post( api.publicity.create.endpoint, data);
  }
  
  update(uuid,data){
    return http.put( api.publicity.update.endpoint+uuid, data );
  }
  
  delete(uuid){
    return http.delete( api.publicity.delete.endpoint+uuid );
  }

}

export default new Publicity();