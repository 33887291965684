//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Company {
 
  list(data){
    return http.get( api.company.get.endpoint, data );
  }

  create(data){
    return http.post( api.company.create.endpoint, data);
  }
  
  update(uuid,data){
    return http.put( api.company.update.endpoint+uuid, data );
  }
  
  delete(uuid){
    return http.delete( api.company.delete.endpoint+uuid );
  }

}

export default new Company();