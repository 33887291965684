<template>
  <b-row>
    <b-col cols="12">
      <h2>Productos y Servicios</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
            >
              Añadir nuevo Producto y Servicio
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span title="ver" @click="show(item.uuid)">
              <i class="iconly-Light-Show"></i>
            </span>
            <span title="editar" @click="edit(item.uuid)">
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span title="eliminar" @click="showDeleteConfirmation(item.uuid)">
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este producto y servicio?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- create -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo Producto y Servicio</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione edificios">
              <v-select
                multiple
                v-model="data.building_uuids"
                :options="Buildins"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Seleccione categoría de ads">
              <v-select
                v-model="data.ads_categories_uuid"
                :options="CategoryAds"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Seleccione una empresa">
              <v-select v-model="data.company_uuid" :options="Companies">
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Foto principal">
              <b-form-file
                class=""
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Logo">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Foto de Publicidad externa">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono">
              <imask-input
                class="form-control"
                :value="data.phone"
                v-imask="phoneMask"
                :unmask="true"
                @complete="onCompletePhone"
                placeholder="+56 000 000 000"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Instagram">
              <b-form-input v-model="data.instagram" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Descripción">
              <b-form-input v-model="data.about" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="created">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar Producto y Servicio</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione edificios">
              <v-select
                multiple
                v-model="data.building_uuids"
                :options="Buildins"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Seleccione categoría de ads">
              <v-select
                v-model="data.ads_categories_uuid"
                :options="CategoryAds"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Seleccione una empresa">
              <v-select v-model="data.company_uuid" :options="Companies">
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Foto principal">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Logo">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Foto de Publicidad externa">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono">
              <imask-input
                class="form-control"
                :value="data.phone"
                v-imask="phoneMask"
                :unmask="true"
                @complete="onCompletePhone"
                placeholder="+56 000 000 000"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Instagram">
              <b-form-input v-model="data.instagram" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Descripción">
              <b-form-input v-model="data.about" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="editd"> Editar </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import sponsors from "@/logic/functions/banners";
import company from "@/logic/functions/company";
import buildings from "@/logic/functions/buildings";
import Publicity from "@/logic/functions/publicity";
import ads from "@/logic/functions/products_and_servicies";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DateTime } from "luxon";
import { IMaskComponent, IMaskDirective } from "vue-imask";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    vSelect,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nombre de la Empresa" },
        { key: "phone", label: "Teléfono de contacto" },
        { key: "instagram", label: "Instagram" },
        { key: "descripcion", label: "Descripción" },
        {
          key: "created_at",
          label: "Válido hasta",
          formatter: (value, key, item) => {
            return (
              DateTime.fromISO(item.created_at).toFormat("dd/MM/yyyy HH:mm ") +
              "hrs"
            );
          },
        },
        { key: "actions", label: "Acciones" },
      ],
      maskDob: {
        mask: "00/00/0000 00:00",
        lazy: false,
      },
      phoneMask: {
        mask: "{+56} 000 000 000",
        lazy: false,
      },
      items: [],
      Buildins: [],
      Companies: [],
      CategoryAds: [],
      data: {
        building_uuids: [],
      },
      fileUpload: {},
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  methods: {
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.sponsors(page);
    },
    onCompletePhone(e) {
      this.inquilinoEdit.phone = e.detail._value;
      this.createAdmin.phone = e.detail._value;
    },
    onCompleteUntilAt(e) {
      console.log(e);
      this.data.until_at = e.detail._value;
    },
    uploadFile(event) {
      this.fileUpload.push(event.target.files[0]);
    },
    show(uuid) {
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-detail-document-admin");
    },
    edit(uuid) {
      this.data = {};
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-edit-document-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid) {
      let items = this.items;
      sponsors
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Patrocinador eliminado");
        })
        .catch((error) => {});
    },
    async created() {
      const params_data = `?company_uuid=${this.data.company_uuid}&active=${
        this.data.active
      }&buildins_uuids=${this.data.building_uuids}&name=${this.data.name}&url=${
        this.data.url
      }&until_at=${DateTime.fromFormat(this.data.until_at, "dd/LL/yyyy T")
        .toUTC()
        .toISO()}&comment=images`;
      const file = new FormData();

      file.append("file", this.fileUpload.file);

      await ads
        .create(file, params_data)
        .then((response) => {
          this.sponsors();
        })
        .catch((error) => {});
    },
    async editd() {
      const params_data = `?company_uuid=${this.data.company_uuid}&active=${
        this.data.active
      }&buildins_uuids=${this.data.building_uuids}&name=${this.data.name}&url=${
        this.data.url
      }&until_at=${DateTime.fromFormat(this.data.until_at, "dd/LL/yyyy T")
        .toUTC()
        .toISO()}&comment=images`;
      const file = new FormData();

      file.append("file", this.fileUpload.file);

      await ads
        .update(this.data.uuid, file, params_data)
        .then((response) => {
          this.sponsors();
        })
        .catch((error) => {});
    },
    async adslist(page = 0) {
      await ads
        .list({
          params: {
            limit: 5,
            page: 0,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async AdsCategory() {
      await Publicity.list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.CategoryAds.push({ code: value.uuid, label: value.name });
          });
        })
        .catch((error) => {});
    },
    async companies(page = 0) {
      await company
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.Companies.push({ code: value.uuid, label: value.name });
          });
        })
        .catch((error) => {});
    },
    async buildingsList(page = 0) {
      await buildings
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.Buildins.push({ code: value.uuid, label: value.name });
          });
        })
        .catch((error) => {});
    },
  },
  beforeMount() {},
  mounted() {
    this.adslist();
    this.companies();
    this.buildingsList();
    this.AdsCategory();
  },
};
</script>
