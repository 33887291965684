//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Banners {
 
  list(data){
    return http.get( api.banners.get.endpoint, data);
  }

  create(file,params){
    return http.post( api.banners.create.endpoint+params , file,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  
  update(uuid,data_params,file){
    return http.put( api.banners.update.endpoint+uuid+data_params, file ,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  
  delete(uuid){
    return http.delete( api.banners.delete.endpoint+uuid );
  }

}

export default new Banners();